/* eslint-disable no-param-reassign */
import axios from "axios";
// import Vue from 'vue'
const baseURL = process.env.VUE_APP_BASE_URL;

export default {
   namespaced: true,
   state: {
      items: [],
      item: {},
      countAll: 0,
   },
   getters: {},
   actions: {
      getData({ commit, rootState }, param) {
         rootState.isLoading = true;
         axios.post(`${baseURL}mppd/table`, param).then(
            (response) => {
               commit("SET_DATA", response.data.rows);
               commit("SET_COUNT", response.data.totalRecords);
               rootState.isLoading = false;
            },
            () => {
               rootState.isLoading = false;
            }
         );
      },
      getDetail({ commit, rootState }, id) {
         rootState.isLoading = true;
         return axios.get(`${baseURL}mppd/${id}`).then(
            (response) => {
               commit("SET_ITEM", response.data);

               rootState.isLoading = false;
               return response.data.item;
            },
            () => {
               rootState.isLoading = false;
            }
         );
      },

      cetakIzin({ rootState }, id) {
         rootState.isLoading = true;
         return axios
            .get(`${baseURL}mppd/cetak/${id}`, {
               responseType: "blob",
            })
            .then(
               (response) => {
                  // commit("SET_IZIN_LAMA", response.data.items);
                  rootState.isLoading = false;
                  return Promise.resolve(response);
               },
               (error) => {
                  rootState.isLoading = false;
                  return Promise.reject(error);
               }
            );
      },
      photo({ rootState }, id) {
         rootState.isLoading = true;
         return axios
            .get(`${baseURL}mppd/photo/${id}`, {
               responseType: "blob",
            })
            .then(
               (response) => {
                  // commit("SET_IZIN_LAMA", response.data.items);
                  rootState.isLoading = false;
                  return Promise.resolve(response);
               },
               (error) => {
                  rootState.isLoading = false;
                  return Promise.reject(error);
               }
            );
      },
   },
   mutations: {
      SET_DATA(state, data) {
         state.items = data;
      },
      SET_ITEM(state, data) {
         state.item = data;
      },
      SET_COUNT(state, data) {
         state.countAll = data;
      },
   },
};
