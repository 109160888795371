export default [
   {
      path: "/mppd/permohonan",
      name: "mppd-permohonan",
      component: () => import("@/views/mppd/Permohonan.vue"),
      meta: {
         pageTitle: "MPPD",
         breadcrumb: [
            {
               text: "MPPD",
               active: false,
            },
            {
               text: "Permohonan",
               active: true,
            },
         ],
      },
   },
   {
      path: "/mppd/scrap",
      name: "mppd-scrap-data",
      component: () => import("@/views/mppd/Scrap.vue"),
      meta: {
         pageTitle: "Permohonan Scrap",
         breadcrumb: [
            {
               text: "MPPD",
               active: false,
            },
            {
               text: "Scrap",
               active: true,
            },
         ],
      },
   },
   {
      path: "/mppd/scrap-document",
      name: "mppd-scrap-document",
      component: () => import("@/views/mppd/ScrapDocument.vue"),
      meta: {
         pageTitle: "Permohonan Scrap Document",
         breadcrumb: [
            {
               text: "MPPD",
               active: false,
            },
            {
               text: "Scrap",
               active: true,
            },
         ],
      },
   },
];
